var w = Object.defineProperty;
var k = (o, t, a) => t in o ? w(o, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : o[t] = a;
var l = (o, t, a) => (k(o, typeof t != "symbol" ? t + "" : t, a), a);
const y = typeof window < "u";
function W() {
  var o = navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || window.opera;
  return /windows phone/i.test(o) ? "windows" : /android/i.test(o) ? "android" : /ipad|iphone|ipod/.test(o) && !window.MSStream ? "ios" : "desktop";
}
const _ = W(), q = !!navigator.userAgent.toLowerCase().match(/(ipad)|(iphone)|(ipod)|(android)|(webos)/i), v = navigator.userAgent.toLowerCase().includes("canvas");
class C {
  constructor(t) {
    l(this, "display");
    l(this, "delay");
    l(this, "widget");
    l(this, "backDrop");
    if (!y)
      return;
    t = Object.assign({}, {
      fontFamily: '"Source Sans Pro", "Arial", sans-serif',
      // Font family for widget texts, defaults to system safe fonts
      textColor: "#222",
      // Widget texts color (any color property value)
      textHeading: "Get 10% OFF",
      // Heading Text
      textDescription: "Scan our QR to download the app on your device and unlock the discount",
      // Description text
      widgetColor: "#fff",
      // Widget BG color
      backDrop: !0,
      backDropZIndex: 888888,
      qrOptions: {
        text: "https://redirect.mobiloud.com/?ios=https://itunes.apple.com/&android=https://play.google.com",
        size: 150,
        margin: 0
      },
      position: "bottom-left",
      // Position of the widget, default 'top'. 'top' | 'bottom'
      animation: "fadeIn",
      // Widget animation, default 'fadeIn'. 'fadeIn' | 'scaleUp' | 'slideBottom' | 'slideTop' | 'slideLeft' | 'slideRight' | null,
      display: "onLoad",
      // Display options, default 'onLoad'. 'onLoad' | 'onScrollDown' | 'onScrollUp'
      radius: "10px",
      // Widget radius with units
      delay: 0,
      // defines how much time to wait until the element shows up
      shadow: !0,
      // If true applies soft shadow, true | false
      useSession: !1,
      zindex: 999999,
      maxWidth: "400px"
    }, t);
    let e = { right: "unset", left: "unset", bottom: "unset", top: "unset" };
    switch (t.position) {
      case "bottom-right":
        e.right = "32px", e.bottom = "32px";
        break;
      case "bottom-left":
        e.left = "32px", e.bottom = "32px";
        break;
      case "top-right":
        e.top = "32px", e.right = "32px";
        break;
      case "top-left":
        e.top = "32px", e.left = "32px";
        break;
      case "center":
        e.right = "50%", e.bottom = "50%";
        break;
      default:
        e.right = "50%", e.bottom = "50%";
    }
    const m = `
    
    .ml-qrWidget {
      display:none;
      position: fixed;
      z-index: ${t.zindex};
      background-color: ${t.widgetColor};
      box-shadow: ${t.shadow ? "0 0 4px 1px #00000014" : "none"} ;
      transition: all 0.3ms ease-in-out;
      font-family: ${t.fontFamily};
      animation: ${t.animation + " 0.5s both"};
      font-size: 1rem;
      border-radius: ${t.radius};
      right: ${e.right};
			bottom: ${e.bottom};
			left: ${e.left};
			top: ${e.top};
      color: ${t.textColor};
      max-width: ${t.maxWidth};
      transform: ${t.position === "center" || !t.position ? "translate(50%, 50%) !important" : "unset"}
    }
    .ml-qrWidget__backDrop{
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #00000073;
      top: 0;
      z-index: ${t.backDropZIndex}
    }

    .ml-qrWidget__qrWrapper {
      min-width: ${t.qrOptions.size}px;
      min-height: ${t.qrOptions.size}px;
      
    }
    .ml-qrWidget__image {
      border-radius: ${t.radius}
    }
    .ml-qrWidget__wrapper {
      display: flex;
      align-items: center;
      padding: 1.8em 1.5em;
      gap: 15px
    }
    
    .ml-qrWidget__description {
      margin: 0
    }

    .ml-qrWidget__title {
      font-weight: bold;
      font-size: 1.3rem;
      margin: 0 0 5px 0
    }

    
    .ml-qrWidget__closebutton {
      position: absolute;
      top: 5px;
      height: unset;
      right: 10px;
      font-size: 30px;
      font-weight: revert;
      cursor: pointer;
      line-height: 30px;
      color: #333;
    }
    
    .toggle--visible{
      display: block;
    }
      
    @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
       }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slideBottom {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideTop {
    0% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0);
    }
  }
      /* CHECK MEDIA CLASSES */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .qrWidget-toggle {display: none !important}
      }

      @supports (-ms-ime-align:auto), (-ms-accelerator:true) {
        .qrWidget-toggle {display: none !important}
      }
    `, g = (i) => Object.entries(i).map((s) => s.map(encodeURIComponent).join("=")).join("&");
    function u(i) {
      const s = document.createElement("div");
      s.className = "ml-qrWidget";
      const c = document.createElement("div");
      c.className = "ml-qrWidget__backDrop";
      const r = document.createElement("div");
      r.className = "ml-qrWidget__wrapper";
      const h = document.createElement("div");
      h.className = "ml-qrWidget__qrWrapper";
      const f = document.createElement("img");
      f.className = "ml-qrWidget__image", f.src = "https://quickchart.io/qr?" + g(i.qrOptions);
      const p = document.createElement("div");
      p.className = "ml-qrWidget__content";
      const b = document.createElement("h4");
      b.className = "ml-qrWidget__title", b.innerHTML = i.textHeading, p.appendChild(b);
      const x = document.createElement("p");
      x.className = "ml-qrWidget__description", x.innerHTML = i.textDescription, p.appendChild(x);
      const d = document.createElement("span");
      return d.id = "ml-qrWidget__closebutton", d.className = "ml-qrWidget__closebutton", d.textContent = "×", d.addEventListener("click", () => {
        s.style.display = "none", c.style.display = "none", i.useSession && window.sessionStorage.setItem("widgetClosed", "true");
      }), h.appendChild(f), r.appendChild(h), r.appendChild(p), r.appendChild(d), s.appendChild(r), document.body.appendChild(c), document.body.appendChild(s), { appWidget: s, backDrop: c };
    }
    let n = u(t);
    this.addStyle(m), this.display = t.display, this.delay = t.delay, this.widget = n, this.backDrop = t.backDrop;
  }
  // (1) inserts css in page
  addStyle(t) {
    const a = document.createElement("link");
    a.setAttribute("rel", "stylesheet"), a.setAttribute("type", "text/css"), a.setAttribute("href", "data:text/css;charset=UTF-8," + encodeURIComponent(t)), document.head.appendChild(a);
  }
  init() {
    const t = window.sessionStorage.getItem("widgetClosed"), a = this.display, e = this.widget, m = this.delay, g = this.backDrop;
    if (!y || JSON.parse(t))
      return;
    g && (e == null || e.backDrop.classList.add("toggle--visible"));
    const u = () => {
      let n = 0;
      switch (a) {
        case "onLoad":
          e == null || e.appWidget.classList.add("toggle--visible");
          break;
        case "onScrollDown":
          window.addEventListener("scroll", function() {
            let i = window.scrollY || document.documentElement.scrollTop;
            i < n ? e == null || e.appWidget.classList.remove("toggle--visible") : e == null || e.appWidget.classList.add("toggle--visible"), n = i;
          });
          break;
        case "onScrollUp":
          window.addEventListener("scroll", function() {
            let i = window.scrollY || document.documentElement.scrollTop;
            i > n ? e == null || e.appWidget.classList.remove("toggle--visible") : e == null || e.appWidget.classList.add("toggle--visible"), n = i;
          });
          break;
      }
    };
    setTimeout(() => {
      u();
    }, m);
  }
}
const S = {
  os: _,
  isMobile: q,
  isCanvas: v
};
y && function(o) {
  o.QrWidget = C, o.deviceData = S;
}(window);
export {
  C as QrWidget,
  S as deviceData
};
